import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 38;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>焚き火ショーでアトピーが悪化</li>
        <li>大晦日も釣りに行きました</li>
        <li>釣果はブルーギル、パーチ、クラッピー、バスの4種</li>
        <li>釣りをしてたら溺れかけました</li>
        <li>ウェイディングする際の注意点</li>
        <li>経験から学ぶことは大きい</li>
        <li>簡単に動画を撮ってます</li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=27hdGOG0NuQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            箕澤屋のロッキー
          </a>
        </li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
